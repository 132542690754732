import React from 'react';
import './Game.css';
import {interval} from 'rxjs'
import {take} from 'rxjs/operators'
import {AnyAction, createStore, Reducer} from 'redux'
import intellijLogo from './assets/intellij.png'
import pwaLogo from './assets/pwa.png'
import reactLogo from './assets/react.png'
import reduxLogo from './assets/redux.png'
import typescriptLogo from './assets/typescript.png'

//redux
enum Actions {
    GAME_START, HIT_SQUARE, JUMP
}
const startGameAction: () => AnyAction = () => {
    return {
        type: Actions.GAME_START
    }
};
const hitSquareAction: (squareId: number) => AnyAction = (squareId: number) => {
    return {
        type: Actions.HIT_SQUARE,
        squareId: squareId
    }
};
const jumpAction: (stepId: number) => AnyAction = (stepId: number) => {
    return {
        type: Actions.JUMP,
        stepId: stepId
    }
};
const gameReducer: Reducer<GameState> = (state: GameState | undefined, action: AnyAction) => {
    if (state === undefined) {
        state = {
            history: [],
            stepId: 0
        };
    }
    switch (action.type) {
        case Actions.HIT_SQUARE:
            const history = state.history.slice(0, state.stepId + 1);
            const current = history[state.stepId];
            if (current.winner) {
                alert('Game has winner already!');
                return state;
            }
            if (current.squares[action.squareId]) {
                alert('This square is occupied.');
                return state;
            }
            const squares: string[] = {...current.squares};
            squares[action.squareId] = marker(current.xIsNext);
            return {
                history: history.concat([{
                    squares: squares,
                    lastHitSquareId: action.squareId,
                    winner: calculateWinner(squares),
                    xIsNext: !current.xIsNext
                }]),
                stepId: history.length,
            };
        case Actions.JUMP:
            return {...state, stepId: action.stepId}
        case Actions.GAME_START:
        default:
            return {
                history: [{
                    squares: Array(9).fill(null),
                    lastHitSquareId: undefined,
                    xIsNext: true,
                    winner: undefined,
                }],
                stepId: 0
            };
    }
};
const store = createStore(gameReducer);

//utils
function marker(xIsNext: boolean) {
    return xIsNext ? 'X' : 'O';
}

function calculateWinner(squares: string[]): string | undefined {
    const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
        const [a, b, c] = lines[i];
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return squares[a];
        }
    }
    return undefined;
}

//game components

interface SquareProps {
    squareId: number;
    highlight: boolean;
    value: string;
    disabled: boolean;
}

function Square(props: SquareProps) {
    return (
        <button
            disabled={props.disabled}
            className={props.highlight ? 'square highlight' : 'square'}
            onClick={() => store.dispatch(hitSquareAction(props.squareId))}>
            {props.value}
        </button>
    );
}

interface BoardRowProps {
    stepSnapshot: StepSnapshot;
    firstSquareId: number;
    disabled: boolean;
}

class BoardRow extends React.Component<BoardRowProps> {
    renderSquare(squareId: number) {
        return (
            <Square
                key={squareId}
                squareId={squareId}
                disabled={this.props.disabled}
                highlight={squareId === this.props.stepSnapshot.lastHitSquareId}
                value={this.props.stepSnapshot.squares[squareId]}
            />
        );
    }

    render() {
        const cols = Array(3).fill(null).map((aa, step) => {
            return (
                this.renderSquare(step + this.props.firstSquareId)
            )
        });
        return (
            <div className="board-row">
                {cols}
            </div>
        );
    }
}

interface BoardProps {
    stepSnapshot: StepSnapshot;
    disabled: boolean;
}

class Board extends React.Component<BoardProps> {
    renderRow(rowId: number, firstSquareId: number) {
        return (
            <BoardRow
                key={rowId}
                disabled={this.props.disabled}
                firstSquareId={firstSquareId}
                stepSnapshot={this.props.stepSnapshot}
            />
        );
    }

    render() {
        const rows = Array(3).fill(null).map((aa, step) => {
            return (
                this.renderRow(step, step * 3)
            )
        });

        return (
            <div className="board">
                {rows}
            </div>
        );
    }
}

interface StepSnapshot {
    squares: string[];
    lastHitSquareId?: number;
    xIsNext: boolean;
    winner?: string;
}

interface StepProps {
    stepId: number;
    active: boolean;
    stepSnapshot: StepSnapshot;
}

class Step extends React.Component<StepProps> {
    render() {
        const descr = this.props.stepId ? 'Go to #' + this.props.stepId : 'Start';
        return (
            <div className={this.props.active ? 'step active' : 'step'}>
                <button onClick={() => store.dispatch(jumpAction(this.props.stepId))}>{descr}</button>
                <Board
                    stepSnapshot={this.props.stepSnapshot}
                    disabled={true}
                />
            </div>
        )
    }
}

interface StepsProps {
    history: StepSnapshot[];
    stepId: number;
}

interface StepsState {
    running: boolean;
}

class Steps extends React.Component<StepsProps, StepsState> {
    constructor(props: StepsProps) {
        super(props);
        this.state = {
            running: false
        }
    }

    replay() {
        if (this.state.running) {
            return;
        }
        this.setState({
            running: true
        });
        interval(500).pipe(
            take(this.props.history.length),
        ).subscribe(stepId => {
            store.dispatch(jumpAction(stepId))
        }, () => {
        }, () => {
            this.setState({
                running: false
            });
        });
    }

    render() {

        const steps = this.props.history.map((step, stepId) => {
            return (
                <Step
                    key={stepId}
                    stepSnapshot={step}
                    stepId={stepId}
                    active={this.props.stepId === stepId}
                />
            )
        });

        return (
            <div className="game-history">
                <button
                    className="replay"
                    disabled={this.state.running}
                    onClick={() => this.replay()}>Replay
                </button>
                <div className="steps">
                    {steps}
                </div>
            </div>
        );
    }

}

function Technologies() {
    return (
        <div className="technologies">
            <img src={intellijLogo} className="logo" alt="React" />
            <img src={pwaLogo} className="logo" alt="Redux" />
            <img src={reactLogo} className="logo" alt="PWA" />
            <img src={reduxLogo} className="logo" alt="Typescript" />
            <img src={typescriptLogo} className="logo" alt="IntelliJ" />
        </div>
    );
}

interface GameProps {
}

interface GameState {
    history: StepSnapshot[];
    stepId: number;
}

class Game extends React.Component<GameProps, GameState> {

    componentDidMount(): void {
        store.subscribe(() => {
            const state = store.getState();
            this.setState(state);
        });
        store.dispatch(startGameAction());
    }

    render() {
        if (!this.state) {
            return null;
        }
        let history: StepSnapshot[] = this.state.history;
        const current: StepSnapshot = history[this.state.stepId];
        let status;
        if (current.winner) {
            status = 'Winner: ' + current.winner + ' !!!';
        } else if (Object.values(current.squares).filter(it => !it).length === 0) {
            status = 'No winner.';
        } else {
            status = 'Next player: ' + marker(current.xIsNext);
        }

        return (
            <React.Fragment>
                <Technologies />
                <h1>
                    TicTacToe
                </h1>
                <div className="game">
                    <div className="game-board">
                        <Board
                            key={'mb'}
                            stepSnapshot={current}
                            disabled={false}
                        />
                    </div>
                    <div className="game-info">
                        <div className="status">{status}</div>
                    </div>
                </div>
                <h2>Time travel</h2>
                <Steps
                    stepId={this.state.stepId}
                    history={this.state.history}
                />
            </React.Fragment>
        );
    }
}

export default Game;